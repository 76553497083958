import {
  Box,
  Flex,
  IconButton,
  Stack,
  Text,
  useBreakpointValue,
  VStack,
} from '@chakra-ui/react';
import HupassLogo from 'components/common/HupassLogo';
import { IoLogoTwitter } from 'react-icons/io5';

export default function Footer() {
  return (
    <Box
      as="footer"
      bg="chakra-subtle-bg"
      color="chakra-subtle-text"
      mt="auto"
      mb="calc(env(safe-area-inset-bottom) * -1)"
      pb="env(safe-area-inset-bottom)"
    >
      <Stack
        maxW="7xl"
        mx="auto"
        direction="row"
        spacing={4}
        px={4}
        h={{ base: 14, md: 16 }}
        justify="space-between"
        align="center"
      >
        <HupassLogo
          text={useBreakpointValue({ base: false, md: false })}
          size={0.75}
        />
        <VStack spacing={0.5}>
          <Text
            textAlign="center"
            fontSize={{ base: 'sm', md: 'md' }}
            fontWeight={'bold'}
          >
            Powered by JagaJaga編集部
          </Text>
          <Text textAlign="center" fontSize={{ base: 'xs', md: 'sm' }}>
            Hupassは非公式の情報提供サービスです。
          </Text>
        </VStack>
        <Flex direction="row" gap={6}>
          <IconButton
            aria-label="twitter"
            as="a"
            href="https://twitter.com/Hupass_info"
          >
            <IoLogoTwitter />
          </IconButton>
        </Flex>
      </Stack>
    </Box>
  );
}
